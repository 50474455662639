import React, { useState, useEffect } from "react";
import { content } from "../data";
import Confetti from "../atoms/Confetti";
import { Card, Grid, Header, Icon, Label, Image } from "semantic-ui-react";

const Intro = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setActive(true), 400);
  }, []);

  return (
    <>
      <Confetti active={active} setActive={setActive} />
      <div style={{ padding: "0.5em" }}>
        <Grid>
          <Card fluid style={{ boxShadow: "none", background: "transparent" }}>
            <Card.Content>
              <Header style={{ fontSize: "3em", margin: "0" }}>Hi, I'm</Header>
              <Header style={{ fontSize: "4em", margin: "0" }}>
                {content.header}
              </Header>
              {
                <Image
                  src={content.avatar}
                  style={{
                    height: "200px",
                  }}
                />
              }
              <div
                style={{
                  fontSize: "1.5em",
                  marginTop: "-3px",
                  marginBottom: "1.5em",
                }}
              >
                {content.about}
              </div>
              <Grid centered>
                <div style={{ maxWidth: 750 }}>
                  {content.stack.map((i, index) => (
                    <Label
                      key={index}
                      size="small"
                      color="white"
                      style={{
                        margin: "5px",
                        fontSize: "1.15em",
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "16px",
                        cursor: "default",
                      }}
                    >
                      {i.name}
                      {i.icon && (
                        <Icon
                          name={i.icon}
                          className="fadeAnimation"
                          style={{ margin: "0 5px", cursor: "default" }}
                        />
                      )}
                    </Label>
                  ))}
                </div>
              </Grid>
            </Card.Content>
            <Grid.Row className="arrowDiv" textAlign="center">
              <Icon
                name="chevron down"
                size="large"
                className="arrowDownIcon"
              />
            </Grid.Row>
          </Card>
        </Grid>
      </div>
    </>
  );
};

export default Intro;
